<template>
  <div class="column">
    <div class="row mt50">
      <div v-if="monthList.length != 0" class="column_base">
        <div class="title">本学期测试完成率</div>
        <div
          v-if="monthType == monthList[0].value"
          class="month_select1 row center mt25"
        >
          <div class="circle1"></div>
          <div class="top_text">{{ monthList[0].name }}</div>
        </div>
        <div
          v-else
          class="month_normal1 row center mt25"
          @click="clickMonth(monthList[0])"
        >
          <div class="circle1 small_circle"></div>
          <div class="top_text">{{ monthList[0].name }}</div>
        </div>
        <div
          v-if="monthType == monthList[1].value"
          class="month_select2 row center"
        >
          <div class="circle2"></div>
          <div class="top_text">{{ monthList[1].name }}</div>
        </div>
        <div
          v-else
          class="month_normal1 row center"
          @click="clickMonth(monthList[1])"
        >
          <div class="circle2 small_circle"></div>
          <div class="top_text">{{ monthList[1].name }}</div>
        </div>
        <div
          v-if="monthType == monthList[2].value"
          class="month_select3 row center"
        >
          <div class="circle3"></div>
          <div class="top_text">{{ monthList[2].name }}</div>
        </div>
        <div
          v-else
          class="month_normal1 row center"
          @click="clickMonth(monthList[2])"
        >
          <div class="circle3 small_circle"></div>
          <div class="top_text">{{ monthList[2].name }}</div>
        </div>
        <div
          v-if="monthType == monthList[3].value"
          class="month_select4 row center"
        >
          <div class="circle4"></div>
          <div class="top_text">{{ monthList[3].name }}</div>
        </div>
        <div
          v-else
          class="month_normal1 row center"
          @click="clickMonth(monthList[3])"
        >
          <div class="circle4 small_circle"></div>
          <div class="top_text">{{ monthList[3].name }}</div>
        </div>
        <div
          v-if="monthType == monthList[4].value"
          class="month_select5 row center"
        >
          <div class="circle5"></div>
          <div class="top_text">{{ monthList[4].name }}</div>
        </div>
        <div
          v-else
          class="month_normal1 row center"
          @click="clickMonth(monthList[4])"
        >
          <div class="circle5 small_circle"></div>
          <div class="top_text">{{ monthList[4].name }}</div>
        </div>
        <div
          v-if="monthType == monthList[5].value"
          class="month_select6 row center"
        >
          <div class="circle6"></div>
          <div class="top_text">{{ monthList[5].name }}</div>
        </div>
        <div
          v-else
          class="month_normal1 row center"
          @click="clickMonth(monthList[5])"
        >
          <div class="circle6 small_circle"></div>
          <div class="top_text">{{ monthList[5].name }}</div>
        </div>
      </div>
      <div class="image_c">
        <div class="title1">小班</div>
        <div class="image_l">
          <img
            v-if="monthType == monthList[0].value"
            class="image"
            src="../../assets/img/home/home/PES/Pic_TB_A.png"
          />
          <div v-if="monthType == monthList[0].value" class="num1">
            {{ num1 }}
          </div>
          <div v-if="monthType == monthList[0].value" class="num2">
            {{ num2 }}
          </div>
          <div v-if="monthType == monthList[0].value" class="num3">
            {{ num3 }}
          </div>
          <img
            v-if="monthType == monthList[1].value"
            class="image"
            src="../../assets/img/home/home/PES/Pic_TB_B.png"
          />
          <div v-if="monthType == monthList[1].value" class="num4">
            {{ num1 }}
          </div>
          <div v-if="monthType == monthList[1].value" class="num5">
            {{ num2 }}
          </div>
          <div v-if="monthType == monthList[1].value" class="num6">
            {{ num3 }}
          </div>
          <img
            v-if="monthType == monthList[2].value"
            class="image"
            src="../../assets/img/home/home/PES/Pic_TB_C.png"
          />
          <div v-if="monthType == monthList[2].value" class="num7">
            {{ num1 }}
          </div>
          <div v-if="monthType == monthList[2].value" class="num8">
            {{ num2 }}
          </div>
          <div v-if="monthType == monthList[2].value" class="num9">
            {{ num3 }}
          </div>
          <img
            v-if="monthType == monthList[3].value"
            class="image"
            src="../../assets/img/home/home/PES/Pic_TB_D.png"
          />
          <div v-if="monthType == monthList[3].value" class="num10">
            {{ num1 }}
          </div>
          <div v-if="monthType == monthList[3].value" class="num11">
            {{ num2 }}
          </div>
          <div v-if="monthType == monthList[3].value" class="num12">
            {{ num3 }}
          </div>
          <img
            v-if="monthType == monthList[4].value"
            class="image"
            src="../../assets/img/home/home/PES/Pic_TB_E.png"
          />
          <div v-if="monthType == monthList[4].value" class="num13">
            {{ num1 }}
          </div>
          <div v-if="monthType == monthList[4].value" class="num14">
            {{ num2 }}
          </div>
          <div v-if="monthType == monthList[4].value" class="num15">
            {{ num3 }}
          </div>
          <img
            v-if="monthType == monthList[5].value"
            class="image"
            src="../../assets/img/home/home/PES/Pic_TB_F.png"
          />
          <div v-if="monthType == monthList[5].value" class="num16">
            {{ num1 }}
          </div>
          <div v-if="monthType == monthList[5].value" class="num17">
            {{ num2 }}
          </div>
          <div v-if="monthType == monthList[5].value" class="num18">
            {{ num3 }}
          </div>
        </div>
        <div class="row_center" style="width: 100%">
          <div class="title1 flex">中班</div>
          <div class="title1">大班</div>
        </div>
      </div>
    </div>
    <div class="white_bg mt125">
      <div class="title">幼儿综合报告</div>
    </div>
    <div class="width1243 column_base mt53">
      <div class="row_center width_max">
        <img
          class="arrow"
          src="../../assets/img/home/home/PES/Icon_Arrow_L_All.png"
          @click="preSmall"
        />
        <div class="gradeName">{{ gradeName1 }}</div>
        <img
          class="arrow"
          src="../../assets/img/home/home/PES/Icon_Arrow_R_All.png"
          @click="nextSmall"
        />
        <div class="flex"></div>
        <div class="look" @click="lookSmall">查看报告</div>
      </div>
      <div class="row_center mt13">
        <div class="name_color">
          班级数量：<span class="num_color">{{ gradeList1.length }}</span>
        </div>
        <div class="status_color1 mrl30"></div>
        <div class="d_text">低完成率</div>
        <div class="status_color2 mrl15"></div>
        <div class="h_text">合格</div>
        <div class="status_color3 mrl15"></div>
        <div class="g_text">高完成率</div>
      </div>
      <div class="line mt8"></div>
      <div class="row width_max">
        <div class="flex column_base mt15">
          <div class="tz_rate_text">体质测试完成率</div>
          <div class="row mt19 width_max">
            <div class="row_center flex">
              <div class="text1">身高测试:</div>
              <div
                v-if="gradeInfo1.founda.height.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.founda.height.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo1.founda.height.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.founda.height.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.founda.height.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo1.founda.height.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo1.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">视力测试:</div>
              <div
                v-if="gradeInfo1.founda.eye.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.founda.eye.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo1.founda.eye.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.founda.eye.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.founda.eye.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo1.founda.eye.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo1.counts }}</div>
            </div>
          </div>
          <div class="row mt9 width_max">
            <div class="row_center flex">
              <div class="text1">体重测试:</div>
              <div
                v-if="gradeInfo1.founda.weight.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.founda.weight.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo1.founda.weight.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.founda.weight.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.founda.weight.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo1.founda.weight.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo1.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">肺活量测试:</div>
              <div
                v-if="gradeInfo1.founda.lung.rate < 70"
                class="low_rate row_center"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.founda.lung.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo1.founda.lung.rate < 90"
                class="hg_rate row_center"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.founda.lung.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.founda.lung.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo1.founda.lung.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo1.counts }}</div>
            </div>
          </div>
          <div class="row mt9 width_max">
            <div class="row_center flex">
              <div class="text1">胸围测试:</div>
              <div
                v-if="gradeInfo1.founda.bust.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.founda.bust.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo1.founda.bust.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.founda.bust.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.founda.bust.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo1.founda.bust.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo1.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">臂展测试:</div>
              <div
                v-if="gradeInfo1.founda.arm.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.founda.arm.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo1.founda.arm.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.founda.arm.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.founda.arm.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo1.founda.arm.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo1.counts }}</div>
            </div>
          </div>
          <div class="row mt9">
            <div class="row_center">
              <div class="text1">体前屈测试:</div>
              <div
                v-if="gradeInfo1.founda.flexion.rate < 70"
                class="low_rate row_center"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.founda.flexion.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo1.founda.flexion.rate < 90"
                class="hg_rate row_center"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.founda.flexion.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.founda.flexion.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo1.founda.flexion.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo1.counts }}</div>
            </div>
          </div>
        </div>
        <div class="flex column_base mt15">
          <div class="tz_rate_text">运动测试完成率</div>
          <div class="row mt19 width_max">
            <div class="row_center flex">
              <div class="text1">跑动测试:</div>
              <div
                v-if="gradeInfo1.sport.run.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.sport.run.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo1.sport.run.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.sport.run.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.sport.run.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo1.sport.run.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo1.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">力量测试:</div>
              <div
                v-if="gradeInfo1.sport.power.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.sport.power.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo1.sport.power.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.sport.power.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.sport.power.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo1.sport.power.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo1.counts }}</div>
            </div>
          </div>
          <div class="row mt9 width_max">
            <div class="row_center flex">
              <div class="text1">跳跃测试:</div>
              <div
                v-if="gradeInfo1.sport.jump.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.sport.jump.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo1.sport.jump.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.sport.jump.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.sport.jump.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo1.sport.jump.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo1.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">协调测试:</div>
              <div
                v-if="gradeInfo1.sport.coordinate.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.sport.coordinate.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo1.sport.coordinate.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.sport.coordinate.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.sport.coordinate.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo1.sport.coordinate.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo1.counts }}</div>
            </div>
          </div>
          <div class="row mt9 width_max">
            <div class="row_center flex">
              <div class="text1">平衡测试:</div>
              <div
                v-if="gradeInfo1.sport.balance.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.sport.balance.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo1.sport.balance.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.sport.balance.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.sport.balance.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo1.sport.balance.rate }}/
              </div>
              <div class="num_color">{{ gradeInfo1.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">物控手测试:</div>
              <div
                v-if="gradeInfo1.sport.ctrlHand.rate < 70"
                class="low_rate row_center"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.sport.ctrlHand.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo1.sport.ctrlHand.rate < 90"
                class="hg_rate row_center"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.sport.ctrlHand.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.sport.ctrlHand.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo1.sport.ctrlHand.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo1.counts }}</div>
            </div>
          </div>
          <div class="row mt9 width_max">
            <div class="row_center flex">
              <div class="text1">敏捷测试:</div>
              <div
                v-if="gradeInfo1.sport.agile.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.sport.agile.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo1.sport.agile.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.sport.agile.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo1.sport.agile.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo1.sport.agile.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo1.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">物控脚测试:</div>
              <div
                v-if="gradeInfo1.sport.ctrlFoot.rate < 70"
                class="low_rate row_center"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.sport.ctrlFoot.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo1.sport.ctrlFoot.rate < 90"
                class="hg_rate row_center"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.sport.ctrlFoot.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo1.sport.ctrlFoot.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo1.sport.ctrlFoot.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo1.counts }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="line mt17"></div>
      <div class="row_center width_max mt72">
        <img
          class="arrow"
          src="../../assets/img/home/home/PES/Icon_Arrow_L_All.png"
          @click="preMiddle"
        />
        <div class="gradeName">{{ gradeName2 }}</div>
        <img
          class="arrow"
          src="../../assets/img/home/home/PES/Icon_Arrow_R_All.png"
          @click="nextMiddle"
        />
        <div class="flex"></div>
        <div class="look" @click="lookMiddle">查看报告</div>
      </div>
      <div class="row_center mt13">
        <div class="name_color">
          班级数量：<span class="num_color">{{ gradeList2.length }}</span>
        </div>
        <div class="status_color1 mrl30"></div>
        <div class="d_text">低完成率</div>
        <div class="status_color2 mrl15"></div>
        <div class="h_text">合格</div>
        <div class="status_color3 mrl15"></div>
        <div class="g_text">高完成率</div>
      </div>
      <div class="line mt8"></div>
      <div class="row width_max">
        <div class="flex column_base mt15">
          <div class="tz_rate_text">体质测试完成率</div>
          <div class="row mt19 width_max">
            <div class="row_center flex">
              <div class="text1">身高测试:</div>
              <div
                v-if="gradeInfo2.founda.height.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.founda.height.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo2.founda.height.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.founda.height.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.founda.height.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo2.founda.height.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo2.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">视力测试:</div>
              <div
                v-if="gradeInfo2.founda.eye.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.founda.eye.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo2.founda.eye.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.founda.eye.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.founda.eye.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo2.founda.eye.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo2.counts }}</div>
            </div>
          </div>
          <div class="row mt9 width_max">
            <div class="row_center flex">
              <div class="text1">体重测试:</div>
              <div
                v-if="gradeInfo2.founda.weight.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.founda.weight.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo2.founda.weight.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.founda.weight.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.founda.weight.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo2.founda.weight.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo2.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">肺活量测试:</div>
              <div
                v-if="gradeInfo2.founda.lung.rate < 70"
                class="low_rate row_center"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.founda.lung.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo2.founda.lung.rate < 90"
                class="hg_rate row_center"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.founda.lung.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.founda.lung.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo2.founda.lung.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo2.counts }}</div>
            </div>
          </div>
          <div class="row mt9 width_max">
            <div class="row_center flex">
              <div class="text1">胸围测试:</div>
              <div
                v-if="gradeInfo2.founda.bust.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.founda.bust.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo2.founda.bust.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.founda.bust.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.founda.bust.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo2.founda.bust.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo2.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">臂展测试:</div>
              <div
                v-if="gradeInfo2.founda.arm.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.founda.arm.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo2.founda.arm.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.founda.arm.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.founda.arm.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo2.founda.arm.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo2.counts }}</div>
            </div>
          </div>
          <div class="row mt9">
            <div class="row_center">
              <div class="text1">体前屈测试:</div>
              <div
                v-if="gradeInfo2.founda.flexion.rate < 70"
                class="low_rate row_center"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.founda.flexion.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo2.founda.flexion.rate < 90"
                class="hg_rate row_center"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.founda.flexion.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.founda.flexion.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo2.founda.flexion.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo2.counts }}</div>
            </div>
          </div>
        </div>
        <div class="flex column_base mt15">
          <div class="tz_rate_text">运动测试完成率</div>
          <div class="row mt19 width_max">
            <div class="row_center flex">
              <div class="text1">跑动测试:</div>
              <div
                v-if="gradeInfo2.sport.run.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.sport.run.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo2.sport.run.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.sport.run.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.sport.run.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo2.sport.run.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo2.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">力量测试:</div>
              <div
                v-if="gradeInfo2.sport.power.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.sport.power.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo2.sport.power.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.sport.power.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.sport.power.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo2.sport.power.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo2.counts }}</div>
            </div>
          </div>
          <div class="row mt9 width_max">
            <div class="row_center flex">
              <div class="text1">跳跃测试:</div>
              <div
                v-if="gradeInfo2.sport.jump.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.sport.jump.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo2.sport.jump.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.sport.jump.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.sport.jump.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo2.sport.jump.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo2.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">协调测试:</div>
              <div
                v-if="gradeInfo2.sport.coordinate.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.sport.coordinate.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo2.sport.coordinate.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.sport.coordinate.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.sport.coordinate.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo2.sport.coordinate.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo2.counts }}</div>
            </div>
          </div>
          <div class="row mt9 width_max">
            <div class="row_center flex">
              <div class="text1">平衡测试:</div>
              <div
                v-if="gradeInfo2.sport.balance.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.sport.balance.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo2.sport.balance.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.sport.balance.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.sport.balance.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo2.sport.balance.rate }}/
              </div>
              <div class="num_color">{{ gradeInfo2.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">物控手测试:</div>
              <div
                v-if="gradeInfo2.sport.ctrlHand.rate < 70"
                class="low_rate row_center"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.sport.ctrlHand.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo2.sport.ctrlHand.rate < 90"
                class="hg_rate row_center"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.sport.ctrlHand.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.sport.ctrlHand.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo2.sport.ctrlHand.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo2.counts }}</div>
            </div>
          </div>
          <div class="row mt9 width_max">
            <div class="row_center flex">
              <div class="text1">敏捷测试:</div>
              <div
                v-if="gradeInfo2.sport.agile.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.sport.agile.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo2.sport.agile.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.sport.agile.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo2.sport.agile.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo2.sport.agile.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo2.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">物控脚测试:</div>
              <div
                v-if="gradeInfo2.sport.ctrlFoot.rate < 70"
                class="low_rate row_center"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.sport.ctrlFoot.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo2.sport.ctrlFoot.rate < 90"
                class="hg_rate row_center"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.sport.ctrlFoot.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo2.sport.ctrlFoot.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo2.sport.ctrlFoot.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo2.counts }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="line mt17"></div>
      <div class="row_center width_max mt72">
        <img
          class="arrow"
          src="../../assets/img/home/home/PES/Icon_Arrow_L_All.png"
          @click="preHigh"
        />
        <div class="gradeName">{{ gradeName3 }}</div>
        <img
          class="arrow"
          src="../../assets/img/home/home/PES/Icon_Arrow_R_All.png"
          @click="nextHigh"
        />
        <div class="flex"></div>
        <div class="look" @click="lookHigh">查看报告</div>
      </div>
      <div class="row_center mt13">
        <div class="name_color">
          班级数量：<span class="num_color">{{ gradeList3.length }}</span>
        </div>
        <div class="status_color1 mrl30"></div>
        <div class="d_text">低完成率</div>
        <div class="status_color2 mrl15"></div>
        <div class="h_text">合格</div>
        <div class="status_color3 mrl15"></div>
        <div class="g_text">高完成率</div>
      </div>
      <div class="line mt8"></div>
      <div class="row width_max">
        <div class="flex column_base mt15">
          <div class="tz_rate_text">体质测试完成率</div>
          <div class="row mt19 width_max">
            <div class="row_center flex">
              <div class="text1">身高测试:</div>
              <div
                v-if="gradeInfo3.founda.height.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.founda.height.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo3.founda.height.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.founda.height.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.founda.height.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo3.founda.height.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo3.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">视力测试:</div>
              <div
                v-if="gradeInfo3.founda.eye.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.founda.eye.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo3.founda.eye.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.founda.eye.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.founda.eye.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo3.founda.eye.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo3.counts }}</div>
            </div>
          </div>
          <div class="row mt9 width_max">
            <div class="row_center flex">
              <div class="text1">体重测试:</div>
              <div
                v-if="gradeInfo3.founda.weight.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.founda.weight.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo3.founda.weight.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.founda.weight.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.founda.weight.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo3.founda.weight.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo3.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">肺活量测试:</div>
              <div
                v-if="gradeInfo3.founda.lung.rate < 70"
                class="low_rate row_center"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.founda.lung.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo3.founda.lung.rate < 90"
                class="hg_rate row_center"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.founda.lung.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.founda.lung.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo3.founda.lung.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo3.counts }}</div>
            </div>
          </div>
          <div class="row mt9 width_max">
            <div class="row_center flex">
              <div class="text1">胸围测试:</div>
              <div
                v-if="gradeInfo3.founda.bust.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.founda.bust.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo3.founda.bust.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.founda.bust.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.founda.bust.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo3.founda.bust.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo3.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">臂展测试:</div>
              <div
                v-if="gradeInfo3.founda.arm.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.founda.arm.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo3.founda.arm.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.founda.arm.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.founda.arm.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo3.founda.arm.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo3.counts }}</div>
            </div>
          </div>
          <div class="row mt9">
            <div class="row_center">
              <div class="text1">体前屈测试:</div>
              <div
                v-if="gradeInfo3.founda.flexion.rate < 70"
                class="low_rate row_center"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.founda.flexion.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo3.founda.flexion.rate < 90"
                class="hg_rate row_center"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.founda.flexion.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.founda.flexion.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo3.founda.flexion.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo3.counts }}</div>
            </div>
          </div>
        </div>
        <div class="flex column_base mt15">
          <div class="tz_rate_text">运动测试完成率</div>
          <div class="row mt19 width_max">
            <div class="row_center flex">
              <div class="text1">跑动测试:</div>
              <div
                v-if="gradeInfo3.sport.run.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.sport.run.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo3.sport.run.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.sport.run.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.sport.run.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo3.sport.run.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo3.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">力量测试:</div>
              <div
                v-if="gradeInfo3.sport.power.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.sport.power.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo3.sport.power.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.sport.power.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.sport.power.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo3.sport.power.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo3.counts }}</div>
            </div>
          </div>
          <div class="row mt9 width_max">
            <div class="row_center flex">
              <div class="text1">跳跃测试:</div>
              <div
                v-if="gradeInfo3.sport.jump.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.sport.jump.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo3.sport.jump.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.sport.jump.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.sport.jump.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo3.sport.jump.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo3.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">协调测试:</div>
              <div
                v-if="gradeInfo3.sport.coordinate.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.sport.coordinate.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo3.sport.coordinate.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.sport.coordinate.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.sport.coordinate.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo3.sport.coordinate.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo3.counts }}</div>
            </div>
          </div>
          <div class="row mt9 width_max">
            <div class="row_center flex">
              <div class="text1">平衡测试:</div>
              <div
                v-if="gradeInfo3.sport.balance.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.sport.balance.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo3.sport.balance.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.sport.balance.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.sport.balance.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo3.sport.balance.rate }}/
              </div>
              <div class="num_color">{{ gradeInfo3.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">物控手测试:</div>
              <div
                v-if="gradeInfo3.sport.ctrlHand.rate < 70"
                class="low_rate row_center"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.sport.ctrlHand.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo3.sport.ctrlHand.rate < 90"
                class="hg_rate row_center"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.sport.ctrlHand.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.sport.ctrlHand.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo3.sport.ctrlHand.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo3.counts }}</div>
            </div>
          </div>
          <div class="row mt9 width_max">
            <div class="row_center flex">
              <div class="text1">敏捷测试:</div>
              <div
                v-if="gradeInfo3.sport.agile.rate < 70"
                class="low_rate row_center mrl20"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.sport.agile.rate }}%</div>
              </div>
              <div
                v-else-if="gradeInfo3.sport.agile.rate < 90"
                class="hg_rate row_center mrl20"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.sport.agile.rate }}%</div>
              </div>
              <div v-else class="high_rate row_center mrl20">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">{{ gradeInfo3.sport.agile.rate }}%</div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo3.sport.agile.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo3.counts }}</div>
            </div>
            <div class="row_center flex">
              <div class="text1">物控脚测试:</div>
              <div
                v-if="gradeInfo3.sport.ctrlFoot.rate < 70"
                class="low_rate row_center"
              >
                <div class="status_color1 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.sport.ctrlFoot.rate }}%
                </div>
              </div>
              <div
                v-else-if="gradeInfo3.sport.ctrlFoot.rate < 90"
                class="hg_rate row_center"
              >
                <div class="status_color2 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.sport.ctrlFoot.rate }}%
                </div>
              </div>
              <div v-else class="high_rate row_center">
                <div class="status_color3 mrl9"></div>
                <div class="text2 mrl9">
                  {{ gradeInfo3.sport.ctrlFoot.rate }}%
                </div>
              </div>
              <div class="text1 mrl11">
                {{ gradeInfo3.sport.ctrlFoot.testCount }}/
              </div>
              <div class="num_color">{{ gradeInfo3.counts }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="line mt17"></div>
    </div>
    <div class="h240"></div>
  </div>
</template>
<script>
export default {
  name: "childrenZHBG",
  components: {},
  data() {
    return {
      monthList: [],
      monthType: "",
      num1: 0,
      num2: 0,
      num3: 0,
      gradeId1: 0,
      gradeName1: "",
      index1: 0,
      gradeList1: [],
      gradeInfo1: {},
      gradeId2: 0,
      gradeName2: "",
      index2: 0,
      gradeList2: [],
      gradeInfo2: {},
      gradeId3: 0,
      gradeName3: "",
      index3: 0,
      gradeList3: [],
      gradeInfo3: {},
    };
  },

  methods: {
    clickMonth(item) {
      this.monthType = item.value;
      this.monthValue = item.name;
      this.getTableData();
      this.getSmallGradeInfo();
      this.getMiddleGradeInfo();
      this.getLargeGradeInfo();
    },
    lookSmall() {
      var url =
        process.env.VUE_APP_NODE1 +
        "?token=" +
        JSON.parse(localStorage.getItem("userInfo")).token;
      // let token = JSON.parse(localStorage.getItem("userInfo")).token;
      window.open(url);
    },
    preSmall() {
      if (this.index1 == 0) {
        this.$hint({
          message: "已经是第一个啦~~",
        }).isShow();
      } else {
        this.index1--;
        this.gradeId1 = this.gradeList1[this.index1].id;
        this.gradeName1 = this.gradeList1[this.index1].name;
        this.getSmallGradeInfo();
      }
    },
    nextSmall() {
      if (this.index1 == this.gradeList1.length - 1) {
        this.$hint({
          message: "已经到最后啦~~",
        }).isShow();
      } else {
        this.index1++;
        this.gradeId1 = this.gradeList1[this.index1].id;
        this.gradeName1 = this.gradeList1[this.index1].name;
        this.getSmallGradeInfo();
      }
    },
    lookMiddle() {
      var url =
        process.env.VUE_APP_NODE1 +
        "?token=" +
        JSON.parse(localStorage.getItem("userInfo")).token;
      // let token = JSON.parse(localStorage.getItem("userInfo")).token;
      window.open(url);
    },
    preMiddle() {
      if (this.index2 == 0) {
        this.$hint({
          message: "已经是第一个啦~~",
        }).isShow();
      } else {
        this.index2--;
        this.gradeId2 = this.gradeList2[this.index2].id;
        this.gradeName2 = this.gradeList2[this.index2].name;
        this.getMiddleGradeInfo();
      }
    },
    nextMiddle() {
      if (this.index2 == this.gradeList2.length - 1) {
        this.$hint({
          message: "已经到最后啦~~",
        }).isShow();
      } else {
        this.index2++;
        this.gradeId2 = this.gradeList2[this.index2].id;
        this.gradeName2 = this.gradeList2[this.index2].name;
        this.getMiddleGradeInfo();
      }
    },
    lookHigh() {
      var url =
        process.env.VUE_APP_NODE1 +
        "?token=" +
        JSON.parse(localStorage.getItem("userInfo")).token;
      // let token = JSON.parse(localStorage.getItem("userInfo")).token;
      window.open(url);
    },
    preHigh() {
      if (this.index3 == 0) {
        this.$hint({
          message: "已经是第一个啦~~",
        }).isShow();
      } else {
        this.index3--;
        this.gradeId3 = this.gradeList3[this.index3].id;
        this.gradeName3 = this.gradeList3[this.index3].name;
        this.getLargeGradeInfo();
      }
    },
    nextHigh() {
      if (this.index3 == this.gradeList3.length - 1) {
        this.$hint({
          message: "已经到最后啦~~",
        }).isShow();
      } else {
        this.index3++;
        this.gradeId3 = this.gradeList3[this.index3].id;
        this.gradeName3 = this.gradeList3[this.index3].name;
        this.getLargeGradeInfo();
      }
    },
    // 获取月份
    getMonth() {
      this.api.pes.ysReportGetMonth().then((res) => {
        this.monthList = res.data;
        this.monthType = this.monthList[0].value;
        this.monthValue = this.monthList[0].name;
        this.getTableData();
        this.getSchoolGradeList();
      });
    },
    // 获取三维数据
    getTableData() {
      let data = {
        month: this.monthType,
        type: 1,
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.pes.ysReportMonthNum(data).then((res) => {
        this.num1 = res.data.z;
        this.num2 = res.data.x;
        this.num3 = res.data.y;
      });
    },
    getSchoolGradeList() {
      let that = this;
      this.api.school
        .getSchoolGradeList(this.$store.state.userInfo.schoolId)
        .then((res) => {
          console.log(res);
          if (res.flag) {
            that.gradeList1 = res.data.小班;
            that.gradeList2 = res.data.中班;
            that.gradeList3 = res.data.大班;
            if (that.gradeList1) {
              that.gradeId1 = that.gradeList1[0].id;
              that.gradeName1 = that.gradeList1[0].name;
              that.getSmallGradeInfo();
            }
            if (that.gradeList2) {
              that.gradeId2 = that.gradeList2[0].id;
              that.gradeName2 = that.gradeList2[0].name;
              that.getMiddleGradeInfo();
            }
            if (that.gradeList3) {
              that.gradeId3 = that.gradeList3[0].id;
              that.gradeName3 = that.gradeList3[0].name;
              that.getLargeGradeInfo();
            }
          }
        });
    },
    // 获取小班班级详情
    getSmallGradeInfo() {
      let data = {
        month: this.monthType,
        schoolId: this.$store.state.userInfo.schoolId,
        gradeId: this.gradeId1,
      };
      this.api.pes.childrenZHBGGet(data).then((res) => {
        this.gradeInfo1 = res.data;
      });
    },
    // 获取中班班级详情
    getMiddleGradeInfo() {
      let data = {
        month: this.monthType,
        schoolId: this.$store.state.userInfo.schoolId,
        gradeId: this.gradeId2,
      };
      this.api.pes.childrenZHBGGet(data).then((res) => {
        this.gradeInfo2 = res.data;
      });
    },
    // 获取大班班级详情
    getLargeGradeInfo() {
      let data = {
        month: this.monthType,
        schoolId: this.$store.state.userInfo.schoolId,
        gradeId: this.gradeId3,
      };
      this.api.pes.childrenZHBGGet(data).then((res) => {
        this.gradeInfo3 = res.data;
      });
    },
  },
  mounted() {
    this.getMonth();
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #e7e7e7;
  align-items: center;
  justify-content: center;
}
.row {
  display: flex;
  flex-direction: row;
}
.center {
  align-items: center;
  justify-content: center;
}
.flex {
  flex: 1;
}
.mt50 {
  margin-top: 50px;
}
.column_base {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.title {
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #001166;
}
.image_c {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 760px;
  margin-left: -56px;
  .title1 {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #001166;
  }
}
.image_l {
  position: relative;
  width: 760px;
  height: 640px;
  .image {
    width: 760px;
    height: 640px;
  }
  .num1 {
    position: absolute;
    top: 36px;
    right: 320px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
  }
  .num2 {
    position: absolute;
    bottom: 89px;
    left: 210px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
  }
  .num3 {
    position: absolute;
    bottom: 139px;
    right: 200px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
  }
  .num4 {
    position: absolute;
    top: 96px;
    right: 320px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
  }
  .num5 {
    position: absolute;
    bottom: 73px;
    left: 170px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
  }
  .num6 {
    position: absolute;
    bottom: 110px;
    right: 160px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
  }
  .num7 {
    position: absolute;
    top: 126px;
    right: 320px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
  }
  .num8 {
    position: absolute;
    bottom: 58px;
    left: 150px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
  }
  .num9 {
    position: absolute;
    bottom: 131px;
    right: 200px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
  }
  .num10 {
    position: absolute;
    top: 66px;
    right: 320px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
  }
  .num11 {
    position: absolute;
    bottom: 40px;
    left: 124px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
  }
  .num12 {
    position: absolute;
    bottom: 101px;
    right: 145px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
  }
  .num13 {
    position: absolute;
    top: 41px;
    right: 320px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
  }
  .num14 {
    position: absolute;
    bottom: 109px;
    left: 240px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
  }
  .num15 {
    position: absolute;
    bottom: 176px;
    right: 280px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
  }
  .num16 {
    position: absolute;
    top: 180px;
    right: 314px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
  }
  .num17 {
    position: absolute;
    bottom: 102px;
    left: 229px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
  }
  .num18 {
    position: absolute;
    bottom: 159px;
    right: 245px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
  }
}
.month_select1 {
  width: 124px;
  height: 48px;
  background: #ffffff;
  border: 3px solid #feba01;
  border-radius: 27px;
  cursor: pointer;
}
.month_normal1 {
  width: 124px;
  height: 48px;
  background: #e8e8e8;
  border: 3px solid #e8e8e8;
  border-radius: 27px;
  cursor: pointer;
}
.month_select2 {
  width: 124px;
  height: 48px;
  background: #ffffff;
  border: 3px solid #6c0000;
  border-radius: 27px;
  cursor: pointer;
}
.month_select3 {
  width: 124px;
  height: 48px;
  background: #ffffff;
  border: 3px solid #169f00;
  border-radius: 27px;
  cursor: pointer;
}
.month_select4 {
  width: 124px;
  height: 48px;
  background: #ffffff;
  border: 3px solid #bd0097;
  border-radius: 27px;
  cursor: pointer;
}
.month_select5 {
  width: 124px;
  height: 48px;
  background: #ffffff;
  border: 3px solid #fe0101;
  border-radius: 27px;
  cursor: pointer;
}
.month_select6 {
  width: 124px;
  height: 48px;
  background: #ffffff;
  border: 3px solid #e9fe01;
  border-radius: 27px;
  cursor: pointer;
}
.mt25 {
  margin-top: 25px;
}
.circle1 {
  width: 35px;
  height: 35px;
  background: #feba01;
  border-radius: 50%;
}
.circle2 {
  width: 35px;
  height: 35px;
  background: #6c0000;
  border-radius: 50%;
}
.circle3 {
  width: 35px;
  height: 35px;
  background: #169f00;
  border-radius: 50%;
}
.circle4 {
  width: 35px;
  height: 35px;
  background: #bd0097;
  border-radius: 50%;
}
.circle5 {
  width: 35px;
  height: 35px;
  background: #fe0101;
  border-radius: 50%;
}
.circle6 {
  width: 35px;
  height: 35px;
  background: #e9fe01;
  border-radius: 50%;
}
.small_circle {
  width: 22px;
  height: 22px;
}
.top_text {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #001166;
  margin-left: 9px;
}
.white_bg {
  width: 100%;
  height: 102px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .title {
    font-size: 50px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #3f3f3f;
  }
}
.width_max {
  width: 100%;
}
.row_center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.width1243 {
  width: 1243px;
}
.arrow {
  width: 73px;
  height: 55px;
  cursor: pointer;
}
.gradeName {
  height: 42px;
  border-top: 6px solid #cecece;
  border-bottom: 6px solid #cecece;
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #172570;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mt125 {
  margin-top: 125px;
}
.mt53 {
  margin-top: 53px;
}
.look {
  width: 194px;
  height: 42px;
  border: 6px solid #cecece;
  border-radius: 27px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #172570;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.status_color1 {
  width: 22px;
  height: 22px;
  background: #feba01;
  border-radius: 50%;
}
.d_text {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #727272;
  margin-left: 9px;
}

.status_color2 {
  width: 22px;
  height: 22px;
  background: #e10000;
  border-radius: 50%;
}
.h_text {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #e10000;
  margin-left: 9px;
}
.status_color3 {
  width: 22px;
  height: 22px;
  background: #001fc5;
  border-radius: 50%;
}
.g_text {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #001fc5;
  margin-left: 9px;
}
.name_color {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #727272;
}
.num_color {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #172570;
}
.mrl30 {
  margin-left: 30px;
}
.mrl15 {
  margin-left: 15px;
}
.mt13 {
  margin-top: 13px;
}
.mt8 {
  margin-top: 8px;
}
.line {
  width: 1243px;
  height: 2px;
  background: #c8c8c8;
}
.mt15 {
  margin-top: 15px;
}
.tz_rate_text {
  font-size: 21px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #172570;
}
.text1 {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8b8b8b;
}
.low_rate {
  width: 102px;
  height: 30px;
  background: #ffffff;
  border: 4px solid #feba01;
  border-radius: 19px;
}
.hg_rate {
  width: 102px;
  height: 30px;
  background: #ffffff;
  border: 4px solid #e10000;
  border-radius: 19px;
}
.high_rate {
  width: 102px;
  height: 30px;
  background: #ffffff;
  border: 4px solid #001fc5;
  border-radius: 19px;
}
.text2 {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1c1642;
}
.mt19 {
  margin-top: 19px;
}
.mrl9 {
  margin-left: 9px;
}
.mrl20 {
  margin-left: 20px;
}
.mt9 {
  margin-top: 9px;
}
.mrl11 {
  margin-left: 11px;
}
.mt17 {
  margin-top: 17px;
}
.mt72 {
  margin-top: 72px;
}
.column_end {
  justify-content: flex-end;
}
.h240 {
  height: 120px;
}
</style>